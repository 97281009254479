import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { ArrowRightIcon, ArrowLeftIcon, RssIcon } from '@heroicons/react/24/outline'
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const BlogTemplate = ({ data, pageContext }) => {

    // Page context provided from gatsby-node.js query
    const { next, prev } = pageContext
    // For convenience extract the markdownRemark 
    const { markdownRemark } = data
    // Break frontmatter up into vars
    const { 
      title, 
      description, 
      date, 
      author, 
      category, 
      hero_image, 
      hero_image_alt, 
      illustrator, 
      illustrator_url } = markdownRemark.frontmatter
    // Get the rendered HTML content
    const html = markdownRemark.html

    /// Social media image configuration for the SEO component
    const socialImage = {
      src: getSrc(hero_image),
      height: 1000,
      width: 3025
    }

    console.log(hero_image);
  
    return (
    <Layout>
      <Seo 
          title={title}
          description={description}
          image={socialImage}
      />
      <aside className="flex flex-row items-center justify-between max-w-screen-md mx-4 mb-10 lg:mx-auto">
          <h2 className="text-4xl font-light text-anomaly-red">
            <Link to="/blog">
              Blog
            </Link>
          </h2>
          <Link to="/rss.xml" className="flex flex-row items-center justify-between text-twitter-blue">
              RSS feed
              <RssIcon className="h-6 ml-2"/>
          </Link>
      </aside>
      <article className="flex flex-col items-center justify-between mx-auto mb-10 rounded-md max-w-prose">
          <h1 className="w-full mb-6 text-3xl font-light text-center text-gray-600">{title}</h1>
          <p className="mx-4 text-center text-gray-500">{author} on {date} in {category}</p>
          <p className="mx-4 text-center text-gray-500">Illustrated by <a className="underline" href={illustrator_url}>{illustrator}</a></p>
          <div className="mt-8 bg-white border border-gray-100">
            { hero_image && hero_image_alt && (
              <GatsbyImage 
              height={992}
              width={3025}
              image={getImage(hero_image)}
              alt={hero_image_alt}
              />
            )}
            <div 
            className="p-6 prose text-gray-600 lg:p-10" 
            dangerouslySetInnerHTML={{ __html: html }}/>
          </div>
      </article>
      <nav className="blog-post-nav">
      <ul className="grid max-w-screen-sm grid-cols-1 gap-8 mx-4 text-gray-500 lg:mx-auto lg:grid-cols-2">
        <li className="flex justify-start">
          {prev && (
            <>
              <Link to={prev.frontmatter.path} rel="prev" className="flex items-center">
                <ArrowLeftIcon className="h-6 mr-2"/>
                {prev.frontmatter.title}
              </Link>
            </>
          )}
        </li>
        <li className="flex justify-end">
          {next && (
            <>
              <Link to={next.frontmatter.path} rel="next" className="flex items-center">
                {next.frontmatter.title}
                <ArrowRightIcon className="h-6 ml-2"/>
              </Link>
            </>
          )}
        </li>
      </ul>
      </nav>
    </Layout>
    )
}

// For the GatsbyImage plugin to work property we need
// to query the childImageSharp property
//
// The component checks to see if there's a valid object
// and renders
export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: {eq: $pathSlug} }) {
      html
      frontmatter {
        path,
        title,
        description,
        date(formatString: "MMMM DD, YYYY"),
        author,
        category,
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        },
        hero_image_alt,
        illustrator,
        illustrator_url
      }
    }
  }
`

export default BlogTemplate